import { initDropdown } from './components/initDropdown';
import { initHamburger } from './components/initHamburger';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initSearchForm } from './components/forms/initSearchForm';
import { initSlider } from './components/sliders/initSlider';

document.addEventListener('DOMContentLoaded', handleOnDOMContentLoaded, false);

function handleOnDOMContentLoaded() {
    initSlider();
    initDropdown();
    initHamburger();
    initSearchForm();
    initRippleAnimation();
}

window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

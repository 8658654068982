import { initUserNotFoundModal } from '../modal/initUserNotFoundModal';
import { initLimitModal } from '../modal/initLimitModal';
import { initAnimationSearch } from '../initAnimationSearch';
import { initPrivateAccountModal } from '../modal/initPrivateAccountModal';
import { fetchDataParse } from '../../services/fetchData';
import { initProfile } from '../initProfile';

export const initSearchForm = () => {
    const forms = document.querySelectorAll('[data-search-form]');

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const username = form.querySelector('[data-search-value]').value;
        const actionValue = form.getAttribute('action');
        const path = window.location.pathname;
        initAnimationSearch();

        try {
            const responseData = await fetchDataParse(username);
            handleResponse(responseData, actionValue, username, path);
        } catch (error) {
            handleError(error);
        }
    };

    const handleResponse = (responseData, actionValue, username, path) => {
        if (responseData.status === 'success' && responseData.username) {
            initAnimationSearch(() => {
                window.location.href =
                    actionValue + '?username=' + encodeURIComponent(username) + '&' + 'redirected=' + path;
            });
        } else if (responseData === 'Profile not found') {
            initAnimationSearch(() => initUserNotFoundModal(username));
        } else if (responseData === 'Too Many Requests') {
            initAnimationSearch(() => initLimitModal());
        } else if (responseData === 'Profile is private') {
            initAnimationSearch(() => initPrivateAccountModal());
        } else {
            console.log('Something went wrong');
        }
    };

    const handleError = (error) => {
        console.error('Error:', error);
    };

    forms.forEach((form) => {
        form.addEventListener('submit', handleFormSubmit);
    });
};

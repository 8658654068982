export const getQueryUsername = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('username')) {
        return queryParams.get('username');
    }
};

export const getQueryFilenames = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const redirectedUrl = params.redirected;
    return redirectedUrl ? redirectedUrl.substring(redirectedUrl.lastIndexOf('/') + 1).replace('.html', '') : null;
};

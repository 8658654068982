import { fetchPosts, fetchProfile } from '../services/fetchData';
import { getQueryUsername } from '../helper-functions/getQueryParams';
import anime from 'animejs/lib/anime.es.js';
import { roundedNumber } from '../helper-functions/roundedNumber';

export async function initProfile() {
    const preloader = document.querySelector('.pre-loader');
    preloader.classList.remove('hidden');
    const username = getQueryUsername();
    await waitForProfile(username);
    preloader.classList.add('hidden');
}

async function waitForProfile(username) {
    let profileData;
    while (!profileData || !profileData.profile || !profileData.profile.previewPicUrl) {
        profileData = await fetchProfile(username);
        await new Promise((resolve) => setTimeout(resolve, 4000));
    }

    await renderProfile(profileData);
    await renderStatistics();
}

function renderProfile(profileData) {
    const userProfileSection = document.querySelector('[data-user-section]');
    const avatar = userProfileSection.querySelector('[data-avatar]');
    const userName = userProfileSection.querySelector('[data-username]');
    const bio = userProfileSection.querySelector('[data-user-bio]');
    const postCount = userProfileSection.querySelector('[data-user-posts]');
    const followerCount = userProfileSection.querySelector('[data-user-followers]');
    const followingCount = userProfileSection.querySelector('[data-user-following]');

    if (!profileData) return;
    const { username, biography, countMedias, countFollowers, countFollowings, previewPicUrl } = profileData.profile;

    avatar.src = previewPicUrl;
    userName.textContent = username;
    bio.textContent = biography;

    animateCount(postCount, countMedias);
    animateCount(followerCount, roundedNumber(countFollowers));
    animateCount(followingCount, roundedNumber(countFollowings));

    postCount.textContent = countMedias;
    followerCount.textContent = roundedNumber(countFollowers);
    followingCount.textContent = roundedNumber(countFollowings);
}

async function renderStatistics() {
    const username = getQueryUsername();

    const posts = await fetchPosts(username);
    const postCount = posts.data.length;

    const totalLikes = posts.data.reduce((total, post) => total + post.countLikes, 0);
    const totalComments = posts.data.reduce((total, post) => total + post.countComments, 0);

    const averageLikesPerPost = totalLikes / postCount;
    const averageCommentsPerPost = totalComments / postCount;

    const countStatistics = document.querySelector('[data-statistics]');
    const countPosts = countStatistics.querySelector('[data-posts-counter]');
    const commentsCount = countStatistics.querySelector('[data-comment-count]');
    const likesCount = countStatistics.querySelector('[data-likes-count]');
    const likesAverageCount = countStatistics.querySelector('[data-likes-avarage-count]');
    const commentsAverageCount = countStatistics.querySelector('[data-comments-avarage-count]');

    animateCount(countPosts, postCount);
    animateCount(commentsCount, totalComments);
    animateCount(likesCount, totalLikes);
    animateCount(likesAverageCount, averageLikesPerPost);
    animateCount(commentsAverageCount, averageCommentsPerPost);

    countPosts.textContent = postCount;
    commentsCount.textContent = totalComments;
    likesCount.textContent = totalLikes;
    likesAverageCount.textContent = averageLikesPerPost.toFixed(0);
    commentsAverageCount.textContent = averageCommentsPerPost.toFixed(0);
}

function animateCount(element, newValue) {
    anime({
        targets: element,
        textContent: newValue,
        round: 1,
        duration: 2000,
        easing: 'easeInOutQuad',
    });
}

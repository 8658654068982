export const initAnimationSearch = (callback) => {
    // VARS
    const animationBlock = document.querySelector('[data-animation-search]');
    const animationParts = animationBlock.querySelectorAll('[data-animation-search-part]');
    const indicatorPanel = animationBlock.querySelector('[data-animation-progress]');
    const hiddenClass = 'hidden';
    const mobileHeader = document.querySelector('[data-nav-mobile]');
    let delay = 1000;
    let progress = 0;
    // INITS

    animationBlock.classList.remove('hidden');
    animationBlock.classList.remove('hidden-out');
    animationBlock.classList.add('start-animation');

    [...animationParts].forEach((part, index, array) => {
        part.classList.add(hiddenClass);
        if (mobileHeader && mobileHeader.classList.contains('active-menu')) {
            mobileHeader.classList.remove('active-menu');
        }
        document.body.style.overflow = 'hidden';
        indicatorPanel.textContent = progress + '%';
        setTimeout(() => {
            part.classList.remove(hiddenClass);

            progress = ((index + 1) / array.length) * 100;
            indicatorPanel.textContent = progress + '%';

            if (index === array.length - 1) {
                if (callback) {
                    setTimeout(() => {
                        callback();
                    }, 900);
                }
                animationBlock.classList.add('hidden-out');
                document.body.style.overflow = 'visible';
            }
        }, delay);

        delay = delay + 1000;
    });
};

export function initDropdown() {
    const dropdownItems = document.querySelectorAll('[data-dropdown]');

    dropdownItems.forEach((item) => {
        const header = item.querySelector('[data-dropdown-toggle]');

        header.addEventListener('click', () => {
            const isOpen = item.classList.contains('active');

            if (!isOpen) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });

        document.body.addEventListener('click', (event) => {
            const isDropdownClick = item.contains(event.target);

            if (!isDropdownClick) {
                item.classList.remove('active');
            }
        });
    });
}

export function initHamburger() {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const mobileMenu = document?.querySelector('[data-nav-mobile]');
    const mainContent = document.querySelector('[data-main-content]');
    const activeClass = 'hamburger--active';
    const activeMenuClass = 'active-menu';

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger);

    // HANDLERS
    function handleOnClickHamburger(event) {
        event.stopPropagation(); // Prevent click event from propagating to the document body

        mobileMenu.classList.toggle(activeMenuClass);
        hamburger.classList.toggle(activeClass);

        if (mobileMenu.classList.contains(activeMenuClass)) {
            mainContent.classList.add('blur');
        } else {
            mainContent.classList.remove('blur');
        }
    }

    document.body.addEventListener('click', (event) => {
        const isHamburgerClick = hamburger.contains(event.target) || mobileMenu.contains(event.target);

        if (!isHamburgerClick) {
            mobileMenu.classList.remove(activeMenuClass);
            hamburger.classList.remove(activeClass);
            if (mainContent.classList.contains('blur')) {
                mainContent.classList.remove('blur');
            }
        }
    });
}
